/*=========================================== CSS FOR FOOTER ===========================================================*/
.bc-top-footer {
	@include clearfix();	

	ul {
		margin: 0;

		li {
			padding: 48px 43px;
			color: #fff;
			@media (max-width: 1199px) {
				padding: 43px 40px;
			}

			&:nth-child(odd) {
				background: $bkgMenu;
			}

			&:nth-child(even) {
				background: $colortheme4;
			}

			.icon {
				font-size: em(24px);
				float: left;
				margin-right: 20px;
				margin-top: 5px;
			}

			h4 {
				text-transform: uppercase;
				margin-bottom: 8px;
			}

			p {				
				font-style: italic;
				color: $colorText1;
			}

			@media (max-width:1199px) {
				padding: 38px 30px;
				white-space: nowrap;
				h4 {
					margin-bottom: 5px;
				}
			}

			@media (max-width: 991px) {
				padding: 20px 15px;
				white-space: nowrap;
				h4 {
					margin-bottom: 3px;
				}
				.icon {
					margin-right: 10px;
				}
			}
		}
	}
	background: $colortheme1;
	padding: 20px 0;
	color: $colorText1;
	.newsletter-topfooter{
		.container{
		}
	}
	#newsletter_block_left{
		margin: 0;
		@include clearfix();
		> span{
			@include fontStyle_7();
			font-size: 20px;
			line-height: 50px;
			@media (max-width: 767px){
				line-height: normal;
			}
		}
		.news_content{
			width: 42%;	
			margin: 0 auto;	
			@media (min-width: 767px){
				width: 60%;
			}
			@media (min-width: 991px){
				width: 40%;
				display: inline-block;
				float: right;
			}
			@media (max-width: 767px){
				width: 100%;
			}	
				h4{
					background: none;
					font-size: 18px;
					border:none;
					color: $colorText;
					margin: 0;
					padding: 0 0 30px;
				}
				.block_content{
					margin: 0;
					.form-group{
						position: relative;
						margin: 0;
						input[type="text"]{
							@extend .btn-style-2;
							height: 50px;
							line-height: 50px;
							background: rgba(0,0,0,0.06);
							border: none;
							color: $colorText1;
							font-size: 14px;
							text-align: left;
							text-transform: none;
							padding: 0 30px;
							box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15);
							font-family: $fontText;
							&:hover{
								background: $colortheme2;
								color: $colorText2;
							}
							&:focus{
								border-color:#494276;
								box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(84, 77, 132);
							}
						}
						.button{
							position: absolute;
							top: 0;
							right: 0;
							@extend .btn-style-2;
							height: 50px;
							line-height: 50px;
							//font-size: 0;
							text-align: center;
							min-width: 0;
							padding: 0 20px;
							font-size: 14px;
							background: $colortheme;
							span{
								display: inline-block;																
								text-align: center;
								// &:before{
								// 	font-family: $fonticont;
								// 	content: "\f1d8";
								// 	font-size: 18px;

								// }
							}

						}
					}
				}
		}
	}

}

.footer-container {
	background: $colorText;	
	padding: 60px 0;
	background-size: cover;
	color: $colorText2;
	font-family: $fontheart;
	font-size: 16px;
	.bc-footer{
		padding: 50px 15px 30px;
		@media (max-width: 767px) {
		}
	}
	.bc-footer2{
		padding: 50px 30px;
		position: relative;
		&:after{
			content: "";
			background: $colortheme1;
			opacity: 0.57;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 0;
		}
		.store-footer{
			z-index: 2;
			position: relative;
			color: $colorText1;
			line-height: 24px;
			.news_content{
				padding: 30px 0;
				.info-store{
					li{
						padding: 0 0 10px;
						i{
							display: none;
						}
					}
				}
				ul:last-child{
					li {
					float: left;
					font-family: "FontAwesome";
					color: #333;
					cursor: pointer;
					-webkit-transition: all .5s;
					-o-transition: all .5s;
					transition: all .5s;
					@media (max-width: 767px) {
						padding-right: 0;
						padding-left: 8px;
					}

					a {
						font-size: 0;
						display: inline-block;
						color: $colorText1;
						border: 1px solid $colortheme2;
						width: 30px;
						height: 30px;
						line-height: 30px;
						background: none;
						border-radius: 50%;
						text-align: center;
						margin-right: 10px;
						&:hover {
							color: $bkgMenu;
							background: $colortheme2;
						}
						@media (max-width: 767px) {
							padding-right: 8px;
						}
						&:before{
							font-size: 14px;
							line-height: 30px;
							display: inline-block;
						}
					}

					&.facebook {

						a:before {
							content: "\f09a";
						}
					}
				
					&.twitter {

						a:before {
							content: "\f099";
						}
					}

					&.google-plus {

						a:before {
							content: "\f0d5";	
						}
					}

					&.rss {

						a:before {
							content: "\f09e";	
						}
					}

					&.linkedin {

						a:before {
							content: "\f0e1";	
						}
					}
				}
				}
			}
		}
	}
	.container {
		padding-bottom: 0;
		@media (max-width: 767px) {
			
		}
	}

	#footer {
	font-size: 14px;
	color: $colorText2;		
		.product-name a{
			color: $colorText1;
			font-size: 14px;
		}
		h4,h3{
			color: $colorText1;
			margin: 0 0 15px;			
			position: relative;			
			text-transform: uppercase;
			font-size: 18px;
			font-weight: bold;
			padding: 0 0 30px;
			font-family: $fontText;
			background: url(../images/bg-title-footer.png) left bottom no-repeat;
			@media (max-width: 767px){
				margin: 0;
			}
		}
		.footer_product{
			margin-top: 30px;
		}
		ul{
			margin: 0;
			padding: 0;
			
			li{
				a{
					line-height: 38px;
					font-size: 16px !important;
					padding: 0 !important;
					text-transform: none !important;
					&:hover{
						color: $colortheme1;
					}
					&:before{
						content: "\f0da";
						font-family: $fonticont;
						padding-right: 8px;
					}
				}
			}
		}
		@media (max-width: 991px) {
			padding-left: 15px;
			padding-right: 15px;
		}	

	.footer-line {
		// height: 1px;
		// background: #ededed;	
		// width: calc(100% - 30px);
		// margin: 40px auto 40px;
	}
	.store-footer{
		.news_logo{
			margin-bottom: 20px;			
		}
		.news_content{
			line-height: 24px;			
		}
		@media (max-width: 767px) {	
			text-align: center;
		}
	}
	.bc-bestsellerproduct{
		position: relative;
		@media (max-width: 767px) {	
			margin-top: 20px;			
		}
		.block-title{
			margin: 0;
			@media (max-width: 767px) {	
				margin: 0 0 30px;
			}
		}
	.bc-filter-product {
		@media (max-width: 767px) {
			clear: both;
		}
		.owl-controls{
			position: absolute;
			top: -60px;
			right: 0;
		}
		h4 {
			text-transform: uppercase;
			color: $colorHeading;
			@include fontStyle_6();
			margin-bottom: 42px;
		}

		.product-container {
			@include clearfix();
			margin-bottom: 22px;
			background: none;
			.left-block {
				width: 70px;
				float: left;
				margin-right: 20px;	
				position: relative;

				.product_img_link:before {
					@include hover-screen();
					background: $colortheme5;				
				}

				img {
					width: 100%;
				}
				&:hover{
					
				}
			}

			.right-block {
				width: calc(100% - 90px);
				float: left; 
				text-align: left;
				padding: 0;
				.button-container{
					display: none;
				}
				.comments_note{
					text-align: left;
				}
			}

			.product-name {
				text-align: left;
				margin-top: 0;
				margin-bottom: 5px;
			}	

			.content_price {
				text-align: left;
				.product-price	{
					color: $bkgMenu;
					margin: 0 10px 0 0;
				}
			}

			.stock-availability {
				display: none;
			}
			.button-container{
				top: auto;
				position: initial;
				.functional-buttons {
					.quick-view-wrapper-mobile {
						display: none;
					}
					.quick-view {
						@include general-quick-view();	
						top: 40%;
						left:40%;				
						span {
							width: 20px;
							height: 20px;
							line-height: 20px;
							font-size: 0;
							background: none;
							box-shadow: none;
							padding: 0;
							&:before {							
								content: "\f002";
							}
						}
					}	
				}
			}
			&:hover {
				.left-block {
					margin: 0 20px 0 0;
					.product_img_link:before {
						@include hover-screen-show();
					}	
				}
				.functional-buttons {
					.quick-view {
						@include general-quick-view-show();
					}	
				}	
			}
		}	
	}
}
	#newsletter_block_left {		
		display: inline-block;
		float: none;
		margin-top: 30px;
		padding: 0 15px !important;
		.news_logo {
			width: 33.333333%;
			float: left; 
			padding-right: 15px;
			padding-top: 55px;

			@media (max-width: 767px) {
				width: 100%;
				padding: 0;
				text-align: center;
				padding-bottom: 20px;				
			}
		}
		h4{
			float: none !important;
			@media (min-width: 767px) {
				display: none;				
			}
			&:after{
				    display: block;
				    content: "\f055";
				    font-family: "FontAwesome";
				    position: absolute;
				    right: 0;				   
				    width: auto;
			}
			&.active{
				&:after{
					content: "\f056";
				}
			}
		}
		.news_content {						
			

			@media (max-width: 991px) {
				padding: 30px 0; 
			}
			@media (max-width: 767px) {
				width: 100%;
				//margin-bottom: 10px;
			}

			h4 {
				@include fontStyle_2();
				color: $colorHeading; 
				text-transform: uppercase;
				line-height: 1.4;
				width: 21%;
				padding: 0;
				padding-right: 10px;
				margin: -3px 0 0;
				span {					
					font-size: em(20px);	
					color: $colorHeading;
					font-style: italic;
					text-transform: none;
				}
				@media (max-width: 991px) {
					width: 100%;
					span {
						display: block;
					}
				}
				@media (max-width: 767px) {
					width: 100%;
					padding-bottom: 15px;
					text-align: center;
					span {
						display: block;
					}
				}
			}

			.block_content {
				@media (max-width: 991px) {
					clear: both;
					margin-bottom: 0;
				}	
			}

			.form-group {
				.button-small {
					color: #fff;
					@include fontStyle_1();
					min-width: 0;
					border-radius: 0;
					line-height: 35px;
					border: 1px solid $colortheme1;
					width: calc(100% - 68%);
					//padding: 0 20px !important;
					&:hover{
						background: lighten($colortheme1,20%);						
					}
				}
			}
		}
		.form-group {
			.form-control{
				border-color: $colortheme1;
				background: none;
				margin-right: 0;
			}
		}
		@media (max-width: 767px) {	
			padding: 0 !important;
		}
	}

	#social_block {		
		float: left !important;		
		padding: 0 0 0 15px!important;
		clear: both;
		ul {
			float: left !important;
			@include clearfix();
			@media (max-width: 767px) {
				text-align: center;
			}

		}

		h4 {
			display: none;
		}
		ul{
			li {
				width: 40px;
				height: 40px;
				border-radius: 3px;
				margin: 0 10px 0 0;							
				@media (max-width: 1199px) {
					
				} 
				@media (max-width: 991px) {
					
				}  
				@media (max-width: 767px) {
					
				} 

				&:hover {
					background: none;  				
				}

				a {
					display: block;
					width: 100%;
					height: 100%;
					color: $colorText2;					
					line-height: 40px !important;
					font-size: 14px !important;
					background: none;
					border-radius: 3px;
					background: #323232;
					text-align: center;

					&:before{
						vertical-align: 0;
						padding: 0;
					}
					@media (max-width: 991px) {
						padding-top: 2px;
					}
					@media (max-width: 767px) {
						padding-top: 6px;
					}

					&:hover {
						color: $colorText1;
						background: $colortheme1;
						border-color: $colortheme1;
					}
				}
			}
		}
		@media (max-width: 767px) {
			margin-top: 10px;
		}
	} 

	.footer-block {
		.comments_note{
			text-align: left;
			.star_content .star:after{
				color: $colortheme1 !important;
			}
		}

		h4 {
			
		}

		.links_footer_left, .links_footer_right {
			width: 50%;
			float: left;

			li {
				&:last-child a {
					padding-bottom: 0;
				}
				a {
					font-weight: normal;
					&:hover {
						color: $bkgMenu;
					}	
				}
			}
		}

		&#block_various_links_footer {
			a {
				text-transform: uppercase;
				font-size: em(11px);
				padding-bottom: 17px;
				display: block;
			}
		}
		
	}

	

	.bc_tags_block {
		h4 {
			text-transform: uppercase;
			color: $colorHeading;
			@include fontStyle_6();
			margin-bottom: 42px;
		}

		a {
			text-transform: uppercase;
			font-size: em(11px);	
			border: 1px solid #ededed;
			padding: 0 18px;
			float: left;
			margin-right: 10px;
			margin-bottom: 12px;
			min-height: 30px;
			padding-top: 5px;
			@media (max-width: 991px) {
				padding-right: 5px;
				padding-left: 10px;
				padding-right: 10px;
			}

			&:hover {
				background: $colortheme;
				color: #fff;
			}
		}
	}

	#block_contact_infos {
		.news_logo{
			margin: 0 0 50px;
		}
		> div ul{
			li {
				padding-left: 0 !important;			
				color: $colorText2;
				font-size: em(16px);
				padding: 0;			
				span {				
					font-family: $fontText;					
					color: $colortheme1 !important; 
					padding-right: 5px;
					float: left;
				}
				i {
					font-size: 0;
				    text-align: center;
				    color: $colortheme1;
				    vertical-align: middle;
				    float: none;
				    width: auto;
				    display: none;
				}
				a{
					line-height: 0 !important;
					&:before{
						content: "";
						padding: 0;
					}
				}
			}
		}
		@media (max-width: 767px) {
			clear: both;
			
		}
		h4{
			//vndisplay: none;
			@media (max-width: 767px){
				
			}
		}
	}
}
}
.bc-bottom-footer {
	padding: 20px 0;
	color: $colorText1;
	position: relative;
	background: #1f1f1f;
	@media (min-width: 767px){
	}
	
	.container {
		@media (max-width: 991px) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.copy-right {
		text-transform: none;
		line-height: 30px;
		span{
			color: $colortheme1;
		}
		@media (max-width: 776px) {
			text-align: left;
			padding-bottom: 20px;
		}
	}

	.bc-bewaer-ft {
		text-align: right;

		a {
			display: inline-block;
		}
		@media (max-width: 776px) {
			text-align: center;
		}
	}
}