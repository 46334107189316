/*================ Global | Sass Mixins ================*/
@mixin clearfix() {
  &:after, &:before {
    content: "";
    display: table;
    clear: both; }
  *zoom: 1;
}

@mixin prefix($property, $value) {
  -webkit-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};
  #{$property}: #{$value};
}

@mixin transition($transition: 0.1s all) {
  @include prefix('transition', #{$transition});
}

@mixin transform($transform: 0.1s all) {
  @include prefix('transform', #{$transform});
}

@mixin animation($animation) {
  -webkit-animation: $animation;
  -moz-animation:    $animation;
  -o-animation:      $animation;
  animation:         $animation;
}

@mixin gradient($from, $to, $fallback) {
  background: $fallback;
  background: -moz-linear-gradient(top, $from 0%, $to 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$from), color-stop(100%,$to));
  background: -webkit-linear-gradient(top, $from 0%, $to 100%);
  background: -o-linear-gradient(top, $from 0%, $to 100%);
  background: -ms-linear-gradient(top, $from 0%, $to 100%);
  background: linear-gradient(top bottom, $from 0%, $to 100%);
}

@mixin backface($visibility: hidden) {
  @include prefix('backface-visibility', #{$visibility});
}

@mixin visuallyHidden {
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px;
}

@mixin desaturate {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

@mixin box-sizing($box-sizing: border-box) {
  -webkit-box-sizing: #{$box-sizing};
  -moz-box-sizing: #{$box-sizing};
  box-sizing: #{$box-sizing};
}

@function em($target, $context: $baseFontSize) {
  @if $target == 0 {
    @return 0;
  }
  @return $target / $context + 0rem;
}

@function color-control($color) {
  @if (lightness( $color ) > 50) {
    @return #000;
  }
  @else {
    @return #fff;
  }
}

@function menu-grid($totalWidth, $curCol, $totalCol) {
  @return $curCol * $totalWidth / $totalCol + 0px;
}

/*============================================================================
  Dependency-free breakpoint mixin
    - http://blog.grayghostvisuals.com/sass/sass-media-query-mixin/
==============================================================================*/
$min: min-width;
$max: max-width;
@mixin at-query ($constraint, $viewport1, $viewport2:null) {
  @if $constraint == $min {
    @media screen and ($min: $viewport1) {
      @content;
    }
  } @else if $constraint == $max {
    @media screen and ($max: $viewport1) {
      @content;
    }
  } @else {
    @media screen and ($min: $viewport1) and ($max: $viewport2) {
      @content;
    }
  }
}

/*============================================================================
  THEME MIXINS
==============================================================================*/

/*HOVER SCREEN*/
@mixin hover-screen() {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.20);
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  visibility: hidden;
  -webkit-transform: scale(0.5,0.5);
  -ms-transform: scale(0.5,0.5);
  -o-transform: scale(0.5,0.5);
  transform: scale(0.5,0.5);
  opacity: 0;

}
@mixin hover-screen-show() {
  -webkit-transform: scale(1,1);
  -ms-transform: scale(1,1);
  -o-transform: scale(1,1);
  transform: scale(1,1);
  opacity: 0.5;
  visibility: visible;
}

/*QUICK VIEW*/
@mixin general-quick-view() {
  z-index: 20;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
   -ms-transform: translate(-50%, -50%);
   -o-transform: translate(-50%, -50%);
   transform: translate(-50%, -50%); 
  span {
    font-size: 14px;
    color: #fff;
    visibility: hidden;
    width: auto;
    height: 30px;
    display: block;
    padding: 0 10px;
    text-align: center;
    background: rgba(0, 0, 0, 0.2);
    box-shadow: rgba(0, 0, 0, 0.16) 0 2px 8px;
    line-height: 30px;
    -webkit-backface-visibility: visible !important;
      backface-visibility: visible !important;

    &:before {
      //content: "\f002";
      display: block;
      font-family: "FontAweSome";
      font-size: 15px;
      color: inherit;           
    }

    @media (max-width: 991px) {
      //width: 30px;
      height: 30px;
      line-height: 30px;    
    }
    @media (max-width: 767px){
      display: none;
      //width: 25px;
      height: 25px;
      line-height: 25px;
      &:before {
        font-size: 10px;
      }
    }
  }
}

@mixin general-quick-view-show() {
  span {
    visibility: visible!important;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown; 
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
}

/*============================================================================
  Accent text
==============================================================================*/

@mixin fontStyle_1() {
  font-size: em(10px);
  font-family: $fontHeading;
}

@mixin fontStyle_2() {
  font-size: em(12px);
  font-family: $fontHeading;
}

@mixin fontStyle_3() {
  font-size: em(14px);
  font-family: $fontHeading;
}

@mixin fontStyle_4() {
  font-size: em(12px);
  font-family: $fontText;
}

@mixin fontStyle_5() {
  font-size: em(16px);
  font-family: $fontHeading;
}

@mixin fontStyle_6() {
  font-size: em(18px);
  font-family: $fontHeading;
}

@mixin fontStyle_7() {
  font-size: em(16px);
  font-family: $fontHeading;
}
@mixin imghover() {
  &:before{
        content: "";
        position: absolute;
        top: 10px;
        left: 10px;
        bottom: 10px;
        right: 10px;
        border: 2px solid $colortheme2;
        opacity: 0;   
        z-index: 1; 
      }      
      &:hover{
        &:before{
          opacity: 1;
        }
      }
}
/* CSS FOR MEGAMENU */
@mixin hoverimg(){
  figure{
      position: relative;
      overflow: hidden;
      >a img{
        transition: all 1s;
        width: 100%;
      }
      figcaption{
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        transition: 0.3s; 
        p{
          margin: 0;
          padding: 0;
          a{
            &:after,&:before{
              position:absolute;
              top:10px;
              left:10px;
              right:10px;
              bottom:10px;
              opacity:1;
              z-index:4;
              content:"";
              transition:transform 0.5s;
            }
            &:after{
              border-right:1px dashed #fff;
              border-left:1px dashed #fff;
              transform:scale(1,0);
            }
            &:before{
              border-top:1px dashed #fff;
              border-bottom:1px dashed #fff;
              transform:scale(0,1);
            }
          }
        }
        img{
          width: 100%;
        }           
      }
      &:hover{
        > a img{
          transform: scale3d(1.1, 1.1, 1);
        }
        figcaption{
          a{
            &:before,&:after{
              transform:scale(1);
              opacity:1;
            }
          }
        }   
    } 
    }   
    
}
@mixin fade-button() {
  -webkit-animation-name: fadeInDown;
          animation-name: fadeInDown; 
          -webkit-animation-duration: .5s;
          animation-duration: .5s;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
          -webkit-animation-delay: 0s;
          animation-delay: 0s;
          display: none;
          margin: 0;          
}
@mixin blog-icon($content){
  font-size: 0;   
    display: inline-block;
    text-align: center;    
    font-weight: normal;
    padding: 0 5px 0 0;
    margin: 0 !important;    
    &:before{
      content: $content;
      font-family: $fonticont;
      font-size: 13px;
      display: inline-block;
      color: $colorText2;
      font-weight: normal;      
    } 
}
@mixin preload() {
  position: fixed;
  width: 43px;
  height: 43px;
  left: 50%;
  top: 50%;
  animation:cssload-animball_two 1.45s infinite;
    -o-animation:cssload-animball_two 1.45s infinite;
    -ms-animation:cssload-animball_two 1.45s infinite;
    -webkit-animation:cssload-animball_two 1.45s infinite;
    -moz-animation:cssload-animball_two 1.45s infinite;
  display: inline-block;
    &:before,&:after,> div:after,> div:before{    
      width: 19px; 
      height: 19px;
      border-radius: 50%;
      position: absolute;
      display: inline-block;
      content: "";
      animation:cssload-animball_one 1.45s infinite ease;
      -o-animation:cssload-animball_one 1.45s infinite ease;
      -ms-animation:cssload-animball_one 1.45s infinite ease;
      -webkit-animation:cssload-animball_one 1.45s infinite ease;
      -moz-animation:cssload-animball_one 1.45s infinite ease;
    }
    &:before{
      background-color:rgb(203,32,37);
      top:0; left:0;      
    } 
    &:after{
      background-color:rgb(248,179,52);
      top:0; left:23px;
    }  
    div:after{
      background-color:rgb(0,160,150);
      top:23px; left:0;
    }     
    > div:before{
      background-color:rgb(151,191,13);
      top:23px; left:23px;
    }
}
@keyframes cssload-animball_one
{
  0%{ position: absolute;}
  50%{top:12px; left:12px; position: absolute;opacity:0.5;}
  100%{ position: absolute;}
}

@-o-keyframes cssload-animball_one
{
  0%{ position: absolute;}
  50%{top:12px; left:12px; position: absolute;opacity:0.5;}
  100%{ position: absolute;}
}

@-ms-keyframes cssload-animball_one
{
  0%{ position: absolute;}
  50%{top:12px; left:12px; position: absolute;opacity:0.5;}
  100%{ position: absolute;}
}

@-webkit-keyframes cssload-animball_one
{
  0%{ position: absolute;}
  50%{top:12px; left:12px; position: absolute;opacity:0.5;}
  100%{ position: absolute;}
}

@-moz-keyframes cssload-animball_one
{
  0%{ position: absolute;}
  50%{top:12px; left:12px; position: absolute;opacity:0.5;}
  100%{ position: absolute;}
}

@keyframes cssload-animball_two
{
  0%{transform:rotate(0deg) scale(1);}
  50%{transform:rotate(360deg) scale(1.3);}
  100%{transform:rotate(720deg) scale(1);}
}

@-o-keyframes cssload-animball_two
{
  0%{-o-transform:rotate(0deg) scale(1);}
  50%{-o-transform:rotate(360deg) scale(1.3);}
  100%{-o-transform:rotate(720deg) scale(1);}
}

@-ms-keyframes cssload-animball_two
{
  0%{-ms-transform:rotate(0deg) scale(1);}
  50%{-ms-transform:rotate(360deg) scale(1.3);}
  100%{-ms-transform:rotate(720deg) scale(1);}
}

@-webkit-keyframes cssload-animball_two
{
  0%{-webkit-transform:rotate(0deg) scale(1);}
  50%{-webkit-transform:rotate(360deg) scale(1.3);}
  100%{-webkit-transform:rotate(720deg) scale(1);}
}

@-moz-keyframes cssload-animball_two
{
  0%{-moz-transform:rotate(0deg) scale(1);}
  50%{-moz-transform:rotate(360deg) scale(1.3);}
  100%{-moz-transform:rotate(720deg) scale(1);}
}
