/*=============================== CSS FOR MODAL / POPUP / LIGHTBOX ======================================================*/
.fancybox-close {
	width: 42px;
	height: 42px; 
	font-size: 0;
	top: 0px;
	right: 0px;
	background: none;
	background:#555454 url('../images/close.png') no-repeat center;
	text-align: center;
	-webkit-transition: all .35s;
	-o-transition: all .35s;
	transition: all .35s;
	&:hover {
		background: url('../images/close.png') no-repeat center $bkgMenu;
	}
}
.fancybox-skin {
	border-radius: 0;
}
/*Newsletter Popup*/
.newsletter-popup {
	#newsletter-popup {
		height: 100%;
		overflow: hidden;		
		background-size: cover !important;
		@media (max-width: 767px) {
			background-position: left center;	
		}
	}
	.fancybox-skin {
		padding: 0 !important;
		overflow: hidden;
	}

	.fancybox-inner {
		overflow: visible !important;
	}

	.popup-image {
		float: left;
		position: absolute;
		@media (max-width: 767px) {
			display: none;
		}
	}

	.title {
		background: none;
		border: none;
		padding: 0;
		margin: 0 0 20px;		
		text-transform: none;
		font-size: em(40px);
		line-height: normal; 
		color: $colortheme1;
		font-weight: bold;		
		@media (max-width: 767px) {
			text-align: center;
		}
	}

	.popup-mail-content {
		
		//padding-right: 35%;
		overflow: hidden;
		position: relative;
		z-index: 10;		
		text-align: center;
		padding: 20px 40px;	
		color: $colortheme2;			
		span{
			font-size: 14px;
			
		}
		@media (min-width: 767px) {
			padding: 70px 40px 30px;			
		}
		@media (max-width: 767px) {
			padding: 20px 30px;
			//text-align: center;
			font-size: 13px;
			.title {
				&:after {
					display: none;
				}
			}
		}
	}

	.block_content {
		padding:0 20px;		
		margin-bottom: 40px;
		overflow: hidden;
		text-align: center;
		position: relative;
		border: 4px solid rgba(255, 255, 255, 0.59);
		border-radius: 10px;
		@media (min-width: 767px) {
			padding: 0;
			width: 300px;
			margin: 0 auto;
		}
		@media (max-width: 767px) {
			
		}

		form {
			position: relative;
		}
		p{
			margin: 0;
		}
		.inputNew {
			min-height: 40px;
			padding: 0 10px;
			display: inline-block;
			width: 100%;
			background: $colortheme2;
			border: none;
			color: $colorText;
			position: relative;
			@media (min-width: 991px) {
				
			}
		}

		input[type="submit"] {
			@extend .btn-style-2; 
			height: 40px;									
			color: $colorText1;
			background: $colortheme1;
			line-height: 40px;				
			padding: 0 10px;
			position: relative;			
			border-radius: 0;
			font-size: 18px;
			font-weight: normal;
			text-transform: uppercase;
			margin: 0;
			transition: 0.4s;
			position: absolute;
			right: 0;
			top: 0;
			border: none;
			&:hover{
				background: $colortheme;
				color: $colorText1;
			}
		}

	}
	.text_content{		
	}
}
/*New Comment Popup*/
#new_comment_form {
	.page-subheading {
		margin-top: 0;
		@include fontStyle_6();
		border-bottom: 2px solid $bkgMenu;
	}

	.product {
		img {
			width: 70px;
			height: auto;
			float: left;
			border: none;
			margin-right: 10px; 
		}

		.product_desc {
			.product_name {
				padding-top: 0;
				margin-bottom: 0;
				@include fontStyle_2();
				color: $bkgMenuHover;
				text-transform: uppercase;
			}	
		}
	}

	.new_comment_form_content {
		background: none;
		padding-left: 0;
		padding-right: 15px;
		@media (max-width: 767px) {
			padding-left: 15px;
		}
		label {
			text-transform: uppercase;
			@include fontStyle_1(); 
		}

		#criterions_list {
			padding-bottom: 0;
		}

		.star_content {
			font-size: 0;
			float: none;
			line-height: 1;

			.cancel {
				float: none;
				display: inline-block;
			}

			.star {
				vertical-align: bottom;
				line-height: 16px;
			}
		}	
	}

	#new_comment_form_footer {
		padding-top: 15px;
		.fr {
			margin-top: -8px;
		}
		#submitNewMessage {
			@extend .btn-style-2;
			border: none;
			height: 35px;
			padding: 0 10px;
			line-height: 35px;
			span {
				border: none;
			}

			&:hover {
				span {
					background: none;
				}
			}
		}	
	}
}
/*Layer Cart Popup*/
.layer_cart_overlay {
	z-index: 100;
	opacity: 0.8;
	filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
}
#layer_cart {
	border-radius: 0;
	z-index: 102;
	>div {
		overflow: hidden; 
	}
	.cross {		
		&:before {
			display: none;
		}
		@extend .fancybox-close;		
	}
	.layer_cart_product {
		@media (max-width: 767px) {
			padding: 20px;
		}
		.title {
			font-family: $fontHeading;
			text-transform: uppercase; 
			font-size: em(18px);
			line-height: 1.4;
			color:  $colortheme1;
			i {
				margin-bottom: 20px;
			}
		}
		.product-image-container {
			padding: 0;
			border: none;
		}
		.layer_cart_product_info {
			padding-top: 20px;
			.product-name {
				text-align: left;
				@include fontStyle_7();
				color: $bkgMenuHover;
				margin-bottom: 0;
			}
			>div {
				strong {

				}
			}
			#layer_cart_product_price {
				font-weight: bold;
				color: $bkgMenu;
				font-size: 18px;
				font-family: $fontHeading;
				padding-left: 10px;
			}
		}
	}
	.layer_cart_cart {
		border-color: #eee;
		background: #fff;
		min-height: 0;
		position: relative;
		@media (max-width: 767px) {
			padding-left: 20px;
			padding-right: 20px;
		}
		.title {
			text-transform: uppercase;
			font-size: em(18px);
			font-family: $fontHeading;
			border-color: #eee;
		}
		.button-container { 
			padding: 30px;
			
			.continue {
				@extend .btn-style-3;
				border: none;
				text-shadow: none;
				color: #fff;
				i{
					display: none;
				}
				span {
					background: none;
					padding: 0;
					border: none;					
				}
				@media (max-width: 767px) {
					width: 100%;
				}
			}
			a.btn {
				@extend .btn-style-3;
				float: right;
				border: none;	
				padding: 0 15px;
				i {
					display: none;
				}
				span {
					border: none;
					padding: 0;
				}
				@media(max-width: 1199px) and (min-width: 992px) {
					clear: both;
					float: left;
				}
				@media (max-width: 767px) {
					float: left;
					width: 100%;
				}
			}
		}
		.layer_cart_row {
			> span {
				font-weight: bold; 
				color: $bkgMenuHover;
				font-family: $fontHeading;
				font-size: em(18px);
				padding-left: 10px;
				&.ajax_block_cart_total {
					color: $bkgMenu;
				}
			}
			strong {
				text-transform: uppercase;
				min-width: 130px;
				display: inline-block !important;
			}
		}
	}
	.crossseling {
		h2 {
			text-transform: uppercase;
			font-family: $fontHeading;
			font-size: em(18px);
			margin-top: 0;
		}
		.crossseling-content {
			border-color: #eee;
		}
		#blockcart_list {
			width: 100%;
			max-width: 100%;
			overflow: visible;
			.bx-wrapper {
				border: 1px solid #eee;

			}
			ul {
				@include clearfix();
				margin-bottom: 0;
				li{
					padding-bottom: 20px;
					text-align: center;
					border-right: 1px solid #eee;
					.product-image-container{
						border: none;
					}
					.product-name {
						margin-bottom: 0;
						text-align: center;
					}	
					.price {
						@include fontStyle_1();
						color: $bkgMenu;
					}
				}
			}
			.bx-prev, .bx-next {
				display: block !important;
				width: 30px !important;
				height: 30px !important;
				text-indent: 0!important;
				@extend .slideNav;
				top: 50%;
				margin-top: -15px!important;
			}
			.bx-prev {
				@extend .slidePrev;
				left: -15px!important;
				margin-left: 0 !important;
				&:before{
					color: #eee;

				}
				&:after{
					display: none !important;
				}

			}
			.bx-next {
				@extend .slideNext;
				right: -15px!important;
				margin-left: 0 !important;
				&:before{
					color: #eee;
				}
				&:after{
					display: none !important;
				}
			}
		}	
	}
}

/*Error Popup*/
.fancybox-error {
	padding-top: 20px;
	padding-bottom: 20px;
	white-space: normal;
}