/*=============================== TYPOGRAPHY ======================================================*/
h1, h2, h3, h4, h5 {
	font-family: $fontHeading;
	line-height: 1;
}

h1 {
	font-size: em(30px);
}

h2 {
	font-size: em(24px);
}

h3 {
	font-size: em(18px);
}

h4 {
	font-size: em(14px);
}

h5 {
	font-size: em(13px);
}

.h1 {
	@extend h1;
}

.h2 {
	@extend h2;
}

.h3 {
	@extend h3;
}

.h4 {
	@extend h4;
}

.h5 {
	@extend h5;
}

ul,ol {
	list-style: none;
	li {
		outline: none;
	}
}

a {
	color: inherit;

	&:active, &:focus {
		outline: none;
		text-decoration: none;
	}

	&:hover {
		background: none;
	}
}