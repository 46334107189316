#slider_row{
  @media (min-width: 991px) {
  }
  .col-sm-12,.col-xs-12{
    padding: 0;
  }
  .ls-l.very_big_white{
    color: $colortheme2 !important;
    padding-bottom: 20px;
    @media (max-width: 991px) {
      &:after{
        display: none;
      }
    }
     @media (max-width: 767px) {
      font-size: 100%;
      &:after{
        display: none;
      }
     }
    span{
      color: $colortheme1;
    }
    &:after{
      position: absolute;
      content: "";
      height: 2px;
      width: 74px;
      background: $colortheme1;
      bottom: 0;
      left: 50%;
      margin-left: -20px;
    }
  }
  .ls-l.medium_text{
    color: $colortheme1 !important;
  }
  .ls-l.small_thin_grey{
     @media (max-width: 991px) {
        font-size: 100%;
        line-height: normal;
     }
  }
  .ls-l.small_thin_grey{
    @media (max-width: 767px) {
      display: none !important;
    }
  }
  .ls-v5 .ls-bottom-slidebuttons{
    bottom:20px; 
    top: auto;
    padding: 4px 20px 0;
    text-align: center;
    a{
          width: 10px;
          height: 10px;
          border-radius: 10px;
          transition: 0.3s;
          margin: 0 5px;
          vertical-align: middle;
    }
    @media (max-width: 991px) and (min-width: 768px) {
     // bottom: 20px;
    }
    @media (max-width: 480px) {
      //bottom: 22px;
    }
  }
  .ls-v5 .ls-bottom-slidebuttons a.ls-nav-active, .ls-v5 .ls-bottom-slidebuttons a:hover{
      width: 10px;
      height: 10px;
            border-radius: 10px;
            background-color: $colortheme1;
  }
}
#full-slider-wrapper{  
  padding-bottom: 15px;
  .ls-slide {    
    .ls-l.big_black {
      color: $colortheme;
      font-size: 30px;
      font-family: $fontHeading;     
      text-transform: none;
      letter-spacing: 0;
      &:after, &:before {
        display: none;
      }
    }
    .ls-l.large_text {
      text-transform: uppercase;
      font-size: 36px;
      text-shadow: none;
    }
    .ls-l.very_big_white {
      font-family: $fontHeading;
      text-transform: uppercase;  
      font-weight: normal;
      @media (max-width: 992px) { 
        font-size: 100%;
        line-height: normal;
      }
    }

    .ls-l.small_text {
      color: $colorText1;
      text-align: left;
      @media (max-width: 380px) { 
        &:after{
          display: none;
        }
      }
      &:hover{
        background: $colortheme1;
        color: $colorText1;
      }
    }
    .ls-l.medium_text {
      display: block; 
      @media (min-width: 991px) { 
      }   
      @media (max-width: 991px) { 
        span {
          font-size: 14px!important;
        }
      }
      @media (max-width: 767px) {
        //font-size: 12px !important;
      }
      @media (max-width: 380px) { 
       
        span {
         // font-size: 13px!important;
        }
      }
      &:hover{
      }
    }
     .ls-l.small_thin_grey{
          @media (max-width: 991px) { 
            font-size: 100%;
            line-height: normal;
          }
         @media (max-width: 767px) { 
            //display: none !important;
            font-size: inherit;
         }
          @media (max-width: 380px) { 
            display: none !important;
            
         }
     }
    .ls-l.big_orange  {
      text-transform: none;
      background-color: transparent;
      @media(max-width: 991px) {
        font-size: 25px;
        line-height: 1;
      }
      @media(max-width: 320px) {
        font-size: 20px;
        text-align: center;
      }
    }
    &:nth-child(2) {
      .ls-l.small_text {
        @media(max-width: 1199px) {
          margin-top: 10px !important;
        }
        @media(max-width: 991px) {
          font-size: 13px;
          line-height: 1;
          width: 60%;
          white-space: normal;
        }
        @media(max-width: 767px) {
        }
      }
    }
  } 
}

.ls-nav-prev,
.ls-nav-next {
  position: absolute;
  background: rgba(255,255,255,0.44);
  display: block;
  width: 35px;
  height: 95px;
  line-height: 95px !important;
  z-index: 3;
  top: 50%;
  text-align: center;
  opacity: 0;
  -webkit-transform: translate(0,-50%);
  -ms-transform: translate(0,-50%);
  -o-transform: translate(0,-50%);
  transform: translate(0,-50%);
  // -webkit-box-shadow: 0 3px 3px 0px rgba(0,0,0,0.1);
  // box-shadow: 0 3px 3px 0px rgba(0,0,0,0.1);
  -webkit-transition: all .35s !important;
  -o-transition: all .35s !important;
  transition: all .35s !important;
  &:before {
    content: "";
    font-family: "FontAweSome";
    display: block;
    font-size: 14px;
    color: $colortheme;
  }
  &:hover {
    background: $bkgMenu;
    &:before {
      color: #fff;
    }
  }
  @media(max-width: 767px) {
  &:before{
      font-size: 14px;
    }
  }
}
.ls-nav-prev {
  left: 0;
  &:before {
    content: "\f053";
  }
  @media (max-width: 991px) {
    opacity: 1;
    margin-left: 0;
    margin-right: 0;
    width: 40px;
      height: 40px;
      line-height: 40px !important; 
  }
  @media(max-width: 767px) {
    width: 30px;
    height: 30px;
    line-height: 30px !important;

  }
}
.ls-nav-next {
  right: 0;
  &:before {
    content: "\f054";
  }
  @media (max-width: 991px) {
    opacity: 1;
    margin-left: 0;
    margin-right: 0; 
    width: 40px;
    height: 40px;
    line-height: 40px !important;
  }
  @media(max-width: 767px) {
    width: 30px;
    height: 30px;
    line-height: 30px !important;    
  }
}
#layerslider {
  &:hover {
    .ls-nav-prev,
    .ls-nav-next  {
      opacity: 1;
      margin-left: 0;
      margin-right: 0;
    }
  }
  .ls-l.small_text {
        background: $colortheme1;
        &:after{
          background: $colortheme5;
        }
      &:hover{
        background: $colortheme;
        color: $colorText1;
        &:after{
           background: $colortheme;
        }
      }
    }
}

.ls-bottom-nav-wrapper {
  @media(max-width: 767px) {
    display: none;
  }
  .ls-bottom-slidebuttons {
    top: -40px !important;
    margin-top: 10px;
    a {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $colortheme2;
      margin: 0 7px;
      &:hover,&.ls-nav-active{
        background: $colortheme1;
      }       
    }
  }
}