/*=========================================== CSS FOR HEADER ===========================================================*/
.header-container {
	background: none;
	@media (max-width: 767px) {
		
	}
}
#header {
	background: none;
	.container {
		overflow: visible;
		@media (min-width: 767px) {
			padding: 0 15px;
		}
	}

	.nav {
		background: none;		
		color:$colorText2;
		font-size: 13px;
		font-family: $fontheart;
		@media (min-width: 767px) {
			height: 45px;
		}
		.row {
			margin-left: -15px;
				margin-right: -15px;
			@media (max-width: 767px) {
				margin-left: -15px;
				margin-right: -15px;
			}
		}

		nav {
			min-height: 38px;
			border-bottom: 1px solid $bordercolor;
			@media (max-width: 767px) {
				padding-left: 15px;
				padding-right: 15px;
				text-align: center;
			}
		}
		
		#languages-block-top, #currencies-block-top,.header_links {			
			border: none;			
			max-height: 100%;
			@media (max-width: 767px) {
				padding: 0px;
				border: none;
				float: none;
				display: inline-block;
			}
			ul{
				z-index: 100;
			}
			.current {
				line-height: 20px;
				padding: 0 10px;
				color: $colorText2;
				cursor: pointer;
				margin-top: 4px;				
				padding: 5px 10px;
				margin-left: 10px;
				@media (max-width: 767px) {					
					line-height: normal;
					background: none;
				}

				&::after {
					content: "\f107";					
					padding-left: 3px;
					vertical-align: 0px;
					text-shadow: none;
					font-family: $fonticont;
					color: $colorText2;
					font-size: 13px;
				}

				span, strong {					
					text-shadow: none;					
					font-weight: normal;
				}				
				&:hover, &.active {
					background: none;
					&:after, span, strong {
						color: $colortheme1;						
					}
				}
				@media (max-width: 767px) {
					span, strong {		
						font-size: 12px
					}
					&::after {
						font-size: 12px;
					}
				}
			}

			ul {
				li{
					&.selected, &:hover {
						background: $bkgMenu;
						a {
							background: $bkgMenu;
						}
					}
				}
				@media (max-width: 767px) {
					right: 0;
					left: auto;
					z-index: 101;
				}
			}
		}

		.social-header {

			@media (max-width: 991px) {
				width: auto;
			}
			@media (max-width: 767px) {
				float: right;
				padding-right: 0;
			}

			ul {

				li {
					float: left;
					font-family: "FontAwesome";
					color: #333;
					cursor: pointer;
					-webkit-transition: all .5s;
					-o-transition: all .5s;
					transition: all .5s;
					@media (max-width: 767px) {
						padding-right: 0;
						padding-left: 8px;
					}

					a {
						font-size: 0;
						padding-top: 9px;
						padding-right: 15px;
						display: inline-block;
						&:hover {
							color: $bkgMenu;
						}
						@media (max-width: 767px) {
							padding-right: 8px;
						}
					}

					&.facebook {

						a:before {
							content: "\f09a";
							font-size: 10px;						
						}
					}

					&.twitter {

						a:before {
							content: "\f099";
							font-size: 10px;							
						}
					}

					&.google-plus {

						a:before {
							content: "\f0d5";	
							font-size: 10px;						
						}
					}

					&.dribbble {

						a:before {
							content: "\f17d";	
							font-size: 10px;						
						}
					}

					&.linkedin {

						a:before {
							content: "\f0e1";	
							font-size: 10px;						
						}
					}
				}
			}
		}

		.header_user_info {
			border: none;
			padding-left: 20px;
			line-height: 50px;
			float: left;	
			font-size: 14px;		
			display: none;
			@media (max-width: 767px) {
				@include clearfix();
				clear: both;
				float: none;
				padding: 0 15px;
				text-align: center;
				display: none;
				span, a {
					float: none!important;
					display: inline-block;
				}
			}

			span {
				float: left;
				padding-right: 5px;
			}

			a {
				padding: 0;
				color: $colorText;
				float: left;
				&:hover {
					background: none;
					color: $bkgMenu;
				}
			}
			@media (max-width: 991px) {
				display: none;
			}
		}

		.header_links {
			@include clearfix();
			display: inline-block;
			float: right;			
			position: relative;
			border: none;
			.dropdown-toggle {
				display: none;
			}

			#header_links {		
				margin: 0;
				z-index: 100;
				li {
					border-left: none;
					color: $colorText2; 
					float: none;
					display: inline-block;
					line-height: 40px;					
					a {
						padding: 0 10px;
						text-transform: capitalize;		
						&:hover {
							background: none;
							color: $colortheme1;
							text-decoration: none;
						}
					}
					&:last-child{
						a{
							border: none;
						}
					}
					.icon {
						margin-right: 5px;
					}
					&:hover{
						background: none;
						a{
							background: none;
						}
					}
					@media (max-width: 767px) {
						line-height: normal;
						padding: 10px 0;
						a{							
							font-size: 12px;
						}						
					}
				}
			}

			@media (max-width: 767px) {				
				margin: 0 auto;
				display: inline-block;
				float: none;

				ul#header_links {
					float: none;
					left: auto;
					text-align: left;
				}
			}	
		}
	}
	.shopping_cart {  
			@include clearfix();
				font-family: $fontText;
				padding-top: 0;					
			    z-index: 10;
			    text-align: left;
			    width: auto;
			    -webkit-border-radius: 50px;
			    padding: 0;
			    line-height: 50px;
			    //position: absolute !important;
			    top: 0;
			    right: 0;
			    float: right;
			    font-family: $fontText;
			> a:first-child {
				background: transparent;
				text-shadow: none;
				color: $bkgMenuHover;
				padding: 0 20px;	
				position: relative;	
				font-size: 16px;
				&:before {										
					color: $colortheme1;
				    width: 32px;
				    height: 32px;
				    background: url("../images/cart-icon.png") center center no-repeat;
				    line-height: 50px;
				    padding-right:0;				    				    
				    text-align: center;
				    content: "";	
				    position: relative;
				    top: 10px;
				    left: 0;			
				    margin-right: 20px;
					@media (max-width: 767px) {
						margin-right: 10px;
						margin-bottom: 0;						
						color: $colortheme2;
					}
				}

				&:after {
					content: "\f107";
					float: none;
					font-size: 11px;
					margin-left: 5px;
					display: none !important;
				}

				&:hover:after {
					content: "\f106";
				}
				@media (min-width: 991px) {
				}
				@media (max-width: 991px) and (min-width: 768px) {
				}				
				@media (max-width: 767px) {
					padding: 0;
					border: none;
				}
				.ajax_cart_quantity {
					display: block !important;
					width: 17px;
					height: 17px;
					position: absolute;					
					border-radius: 50%;
					top: 8px;
					left: 42px;
					background: $colortheme1;
					color: $colorText1;
					text-align: center;
					padding-right: 0 !important;					
					line-height: 17px;
					font-weight: normal;
					font-size: 13px;					
					@media (max-width: 767px) {						
						left: auto;
						right: 0;
    					top: 5px;
    					background: $colortheme2;
    					color: $colortheme1;
					}
				}

				b,span {
					//float: left;
					font-weight: normal!important;
					color: $colorText;
					font-size: 16px;
					font-family: inherit;	
					font-family: $fontheart;				
				}

				.ajax_cart_product_txt,
				.ajax_cart_product_txt_s {
					display: none !important;
				}

				.ajax_cart_no_product {
					min-width: 50%; 
					@media (max-width: 767px) {
						display: none !important;
					}
				}

				.ajax_cart_total {
					//float: left;
					//min-width: 50%;
					@media (max-width: 767px) {
						display: none !important;
					}
				}

				b {					
					color: $colorText;
					text-shadow: none;					
					border-left: 1px solid $bordercolor;	
					width: 50%;
					padding-left: 15px;	
					@media (max-width: 991px) and (min-width: 768px) {
						padding: 0;
						border: none;
					}
					@media(max-width: 767px) {
						display: none !important;
					}
				}
			}
			@media (max-width: 991px) and (min-width: 768px) {
				top: 15px;
			}
			@media(min-width: 991px) {
				margin-right: 12%;
				margin-top: 20px;
			}
			@media(max-width: 767px) {
				border: none;
				padding: 0 10px 0 0;
				float: none !important;
				top: 0;
				width: auto;
				position: absolute;
			}
		}
		.bc-button-top{
			a{
				position: absolute;
				top: 0;
				right: 0;
				line-height: 48px;
				background: $bordercolor;
				color: $colortheme1;
				text-transform: uppercase;
				font-weight: bold;
				padding: 0 30px;
				cursor: pointer;
				margin-top: 20px;
				display: inline-block;
				&:after{
					content: "";
					border: 1px dashed #cfcfcf;
					position: absolute;
					top: 5px;
					bottom: 5px;
					left: 5px;
					right: 5px; 
				}
				@media(max-width: 991px) {
					display: none;
				}
				&:hover{
					color: $colortheme;
				}
			}
		}
		.contact-header{
			display: inline-block;			
			margin: 0;
			line-height: 40px;
			float: left;
			.icon-mailicon{
				float: left;
				margin-right: 15px;
				img{
					vertical-align: middle;
				}
			}
			span{				
				color: $colorText2;
				margin-right: 10px;
				i{
					padding-right: 10px;					
				}								
			}
			@media (max-width: 767px) {
				display: none;
			}
			@media (max-width: 991px) {
				span.contact-info-address{
					display: none;
				}
			}
		}
	.header-middle {		
		padding: 30px 0;
		@media (min-width: 767px) {
			.container{
				padding: 0 15px;
			}
		}	
		@media (max-width: 767px) {
			padding: 0;
			.contact-header{
				display: none;
			}
			.col-sm-6{
				width: calc(100% - 112px);
				position: absolute;
				#search_block_top{
					width: 100%;
					top: 5px;
				}
			}
			.row{
				.col-xs-12:nth-child(3){
					height: 50px;
					background: $colortheme1;
				}
			}
		}

		.container {
			// padding-left: 15px;
			// padding-right: 15px;
		}		
		#header_logo {
			padding-left: 0;
			padding-top: 0;	
			z-index: 10;
			text-align: center;		
			.img-responsive{
				display: inline-block;
			}
			@media (min-width: 1200px) {
				
			}
			@media (max-width: 1199px) and (min-width: 992px) {
				
			}
			@media (max-width: 991px) {
				padding: 0 15px;				
				
				img {
					
				}

				>a {
					display: inline-block;
				}
			}
			@media (max-width: 767px) {
				padding: 20px 0;
				text-align: center;
			}
		}		
		#search_block_top {
			padding-top: 20px;			
			position: relative;
			z-index: 100;
			border-bottom: 1px solid $bordercolor;
			p{
				margin: 0;
			}
			form {
				position: relative;
		
			}

			@media(max-width: 991px) {
				//width: 58.33333%;
				//padding-left: 15px;
			}
			@media(max-width: 767px) {
				width: calc(100% - 130px);
				width: 100%;
				padding: 0;
				clear: both;
				display: inline-block;
				margin-left: 60px;
				margin: 0;
			}

			.search_query {
				width: 100%;
				height: 46px; 
				padding: 0 73px 0 20px; 
				border-radius: 0;
				background: $colortheme2 !important;
				border: none;
				@media (max-width: 767px) {
					height: 30px;
					padding-right: 63px;
					margin: 5px 0 0;
					padding-left: 10px;
				}
			}

			.button-search {
				width: 46px;
				height: 46px;
				min-width: 0;
				position: absolute;
				background: none;
				right: 0;
				top: 0;
				border: none;
				padding: 0;
				border-radius: 0;				
				color: $colorText2;
				@media (max-width: 767px) {
					height: 30px;
					width: 30px;
					line-height: 30px;
					right: 0;
					top: 5px;
					// background: $colortheme;
					// border-color: $colortheme;
				}

				&:before {
					font-size: 16px;
				}
				&:hover {
					color: #fff;
					background: $colortheme1;
					border-color: $colortheme1;
				}
			}

			#show_result {
				position: absolute;
				background-color: lighten($colortheme1,35%);
				width: 100%;
				left: 0;
				top: 100%;
				z-index: 100;
				color: $colorText;
				box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
				@media (max-width: 767px) {
					width: calc(100vw - 30px);
					margin-left: -50px;
				}
				.result {
					width: 100%;
					padding: 15px;
				}
				#closedform {
					position: absolute;
					right: 0px;
					top: 0px;
					width: 30px;
					height: 30px;
					text-align: center;
					line-height: 30px;
					cursor: pointer;
					font-size: 14px;
					font-family: $fontHeading;
					&:hover {
						color: $bkgMenu;
					}
				}
				h1 {
					@include fontStyle_6();
					text-transform: uppercase;
					margin: 0;
					padding: 0 0 20px;
				}
				#search-products_block {
					li {
						border-bottom: 1px dashed $colortheme1;
						.products-block-image {
							float: left;
							margin-right: 10px;
						}
						h5 {
							margin-bottom: 0;
						}
						.product-name {
							color: $colorText;
						}
						.price-box{
							.price {
								color: $bkgMenu;
								font-size: em(14px);
								font-family: $fontHeading;
							}
						}
						&:last-child {
							border: none;
							margin-bottom: 0;
							padding-bottom: 0;
						}
					}	
				}
			}
		}

		@media (max-width: 991px) {
			.col-sm-3 {
				
			}
		}

		@media (max-width: 767px) {
			.col-sm-3 {
						

			}
		}

		
	}

	/*cart block*/
	&#header {
		.cart_block {
			top: 100%;
			width: 290px;
			background: #fff;
			border: 1px solid #eee;
			color: $bkgMenuHover;
			-webkit-box-shadow: 0 5px 10px rgba(238,238,238,0.4);
			box-shadow: 0 5px 10px rgba(238,238,238,0.4);
			.products {
				padding: 20px 20px 0;
				margin: 0;
				height: 310px;
    			overflow: auto;
			}
			.cart_block_list {
				.remove_link {
					right: 0;
					top: 0;
					.ajax_cart_block_remove_link{
						color: $bkgMenuHover;
						width: 20px;
						height: 20px;
						text-align: right;
						&:hover{
							color: $bkgMenu;
						}
						&:before{
							content:"\f1f8";
							font-size: 13px;
						}
					}	
				}
			}
			dt{
				-webkit-box-shadow: none;
				box-shadow: none;
				background: none;
				padding: 0;
				border-bottom: 1px solid #eee;
				padding-bottom: 0;
				margin-bottom: 20px;
				&.last_item {
					border: none;
					margin-bottom: 0;
				}
			}
			.cart-prices {
				background: none;
			}
			.cart-buttons {
				background: none;
			}
			.cart-info {
				.product-name {
					.cart_block_product_name {
						color: $bkgMenuHover;
						a {

						}
					}
					.quantity-formated {
						.quantity{
							font-size: em(12px);	
						}
					}	
				}
			}
			.product-atributes {
				a {
					color: $bkgMenuHover;
				}	
			}
			.cart-prices {
				.cart-prices-line {
					border-color: #eee;
				}	
			}
			.price {
				color: $bkgMenu;	
				font-size: em(14px);
				padding-left: 10px;
			}
			.cart-buttons {
				padding-top: 0;
				#button_order_cart {
					@extend .btn-style-3;					
					padding: 0;
					span {
						border: none;
						background: none;
						padding: 0;
					}
				}	
			}
			@media (max-width: 767px) {
				
			}
		}
		.banner {
			background: $colortheme1;
			a {
				max-width: 100%;
				img {
					max-width: 100%;
				}
			}
			#banner_block_popup{
				.text_content {
					color: $colorText1;
					.ads-bar-link{
						color: $colorText1;
					}
					p{
						font-weight: normal;
						font-size: 14px;
					}
				}
			}
		}
	} 
}